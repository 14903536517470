import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

const config = {
  apiKey: "AIzaSyAE7ZnYtGopyVi5QGVs-xzMrrv01eCXKT8",
  authDomain: "pusici-cz.firebaseapp.com",
  databaseURL: "https://pusici-cz.firebaseio.com",
  projectId: "pusici-cz",
  storageBucket: "pusici-cz.appspot.com",
  messagingSenderId: "826467240912",
  appId: "1:826467240912:web:0bebb4bc2afcfe8a9307d5",
  measurementId: "G-HFGNR6G1VY",
};

firebase.initializeApp(config);

const auth = firebase.auth();
const google = new firebase.auth.GoogleAuthProvider();
const database = firebase.database();
const firestore = firebase.firestore();
const functions = firebase.functions();
const storage = firebase.storage();

export { auth, google, database, firestore, functions, storage };
