<template>
  <div>
    <v-navigation-drawer v-model="drawer" fixed temporary app>
      <v-list nav>
        <v-list-item-group v-model="group">
          <div v-if="user" class="text-center no-select">
            <v-avatar v-if="user.photoURL" size="100" class="my-2">
              <img
                :src="user.photoURL"
                :alt="user.displayName"
                referrerpolicy="no-referrer"
              />
            </v-avatar>
            <v-avatar
              v-else-if="avatar"
              color="blue-grey"
              size="70"
              class="my-2"
            >
              <span class="white--text display-1">{{ avatar }}</span>
            </v-avatar>
            <h2>{{ user.displayName }}</h2>
            <p>{{ user.email }}</p>
            <v-divider class="my-5"></v-divider>
          </div>
          <v-list-item
            v-for="item in items"
            :key="item.name"
            :to="{ name: item.name }"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <div v-if="user">
            <v-divider class="my-5"></v-divider>
            <v-list-item
              @click="logout"
              dark
              class="blue-grey"
              active-class="white--text"
            >
              <v-list-item-icon>
                <v-icon>mdi-logout-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Odhlásit se</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>
      <!-- <template v-slot:append>
        <div class="pa-2">
          <v-btn block>Logout</v-btn>
        </div>
      </template> -->
    </v-navigation-drawer>
    <v-btn
      @click="drawer = true"
      fixed
      fab
      dark
      large
      top
      left
      color="blue-grey"
    >
      <v-icon dark>mdi-menu</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  data: () => ({
    drawer: false,
    group: null,
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
    avatar() {
      return this.user.displayName ? this.user.displayName[0] : null;
    },
    items() {
      return this.$store.getters.navigation;
    },
  },
  methods: {
    logout() {
      this.drawer = false;
      this.$auth.signOut().then(() => {
        this.$router.replace({ name: "LoginUser" });
      });
    },
  },
};
</script>

<style scoped></style>
