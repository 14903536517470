import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "animate.css/animate.min.css";
import Alerts from "@/plugins/alerts";
import Firebase from "@/plugins/firebase";

Vue.config.productionTip = false;
Vue.use(Alerts); // register alerts plugin
Vue.use(Firebase);

let app = null;

// wait for firebase auth to init before creating the app
Vue.auth.onAuthStateChanged(async (user) => {
  let claims = {};

  if (user) {
    // database reference
    let metadataRef = Vue.database.ref("metadata/" + user.uid + "/claims");
    // remove previous listener
    metadataRef.off();
    let run = false;
    // add new database listener (it is executed when claims are updated)
    metadataRef.on("value", () => {
      // do not run for the first time, when the listener is attached
      if (run) {
        router.go(); // reload page => get updated token + execute router guards
      } else run = true;
    });
    try {
      let token = await user.getIdTokenResult(true); // force refresh regardless of token expiration
      claims = token.claims;
    } catch (error) {
      console.error(error);
    }
  }

  console.log("USER:", user);
  console.log("CLAIMS:", claims);
  store.commit("updateUser", user);
  store.commit("updateClaims", claims);
  store.dispatch("updateNavigation");

  // run the application last
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
