<template>
  <v-container>
    <psc-header title="O nás"></psc-header>
  </v-container>
</template>

<script>
import Header from "@/components/Header";

export default {
  components: { "psc-header": Header },
  data() {
    return {};
  },
};
</script>
