<template>
  <div>
    <div class="mb-10 mr-n8">
      <v-btn x-large color="blue--text" @click="google" class=""
        ><v-icon left>mdi-google</v-icon><span>přes Google</span></v-btn
      >
      <v-tooltip right max-width="300">
        <template v-slot:activator="{ on, attrs }">
          <v-icon right v-bind="attrs" v-on="on"
            >mdi-help-circle-outline</v-icon
          >
        </template>
        <span
          >Preferovaná metoda. Propojí tvůj Google účet s naším webem. Nemusíš
          si pamatovat další přihlašovací údaje.</span
        >
      </v-tooltip>
    </div>
    <psc-text-divider text="Nebo" class="mb-10"></psc-text-divider>
  </div>
</template>

<script>
import TextDivider from "@/components/TextDivider";

export default {
  components: { "psc-text-divider": TextDivider },
  created() {
    let vm = this;
    this.$auth
      .getRedirectResult()
      .then(function (result) {
        // if (result.credential) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // var token = result.credential.accessToken;
        // }
        if (result.user) vm.$router.replace({ name: "Home" });
      })
      .catch(function (error) {
        console.error(error.code, error.message);
        vm.$alerts.error(error.message);
        // The email of the user's account used.
        // var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        // var credential = error.credential;
      });
  },
  methods: {
    google() {
      this.$auth.signInWithRedirect(this.$google);
    },
  },
};
</script>

<style scoped></style>
