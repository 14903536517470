<template>
  <header>
    <div class="text-right text-md-center">
      <h1 class="text-h4 text-sm-h3 mt-5 mx-5 mb-15 pl-15 pl-sm-0">
        {{ title }}
      </h1>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["title"],
};
</script>

<style scoped></style>
