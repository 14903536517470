import Vue from "vue";

export default new Vue({
  data: {
    alerts: [],
  },
  methods: {
    add(alert) {
      this.alerts.unshift(alert);
      setTimeout(() => {
        alert.show = true;
      }, 100);
    },
    remove(alert) {
      clearTimeout(alert.timer);
      alert.show = false;
      setTimeout(() => {
        let index = this.alerts.indexOf(alert);
        this.alerts.splice(index, 1);
      }, 1000);
    },
    trigger(alert) {
      if (alert.delay != 0) {
        let timer = setTimeout(() => {
          this.remove(alert);
        }, alert.delay * 1000);
        alert.timer = timer;
      }
      this.add(alert);
    },
    success(message, delay) {
      console.log("ALERT success:", message);
      this.trigger(getNewAlertObject("success", message, delay));
    },
    error(message, delay = 10) {
      console.error("ALERT error:", message);
      this.trigger(getNewAlertObject("error", message, delay));
    },
    info(message, delay) {
      console.log("ALERT info:", message);
      this.trigger(getNewAlertObject("info", message, delay));
    },
  },
});

// private function
function getNewAlertObject(type, message, delay = 5) {
  return {
    key: Math.random(),
    show: false,
    timer: null,
    type: type,
    message: message,
    delay: delay,
  };
}
