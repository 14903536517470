<template>
  <div class="background fill-height">
    <v-container>
      <psc-header title="Vytvoření nového účtu"></psc-header>
      <div
        ref="register"
        class="register mx-auto text-center my-15 pa-5 pa-sm-10 animate__animated animate__zoomIn animate__faster"
      >
        <psc-google-login></psc-google-login>
        <v-expansion-panels
          hover
          :style="`width: ${formWidth}`"
          class="mx-auto"
          v-model="panel"
        >
          <v-expansion-panel>
            <v-expansion-panel-header hide-actions
              ><div class="title text-center text-uppercase">
                Nový účet
              </div></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-form
                ref="form"
                v-model="formValid"
                class="register__form mx-auto"
              >
                <v-text-field v-model="firstName" label="Jméno"></v-text-field>
                <v-text-field
                  v-model="lastName"
                  label="Příjmení"
                ></v-text-field>
                <v-text-field
                  v-model="email"
                  label="Email"
                  required
                  :rules="emailRules"
                >
                </v-text-field>
                <v-text-field
                  v-model="password"
                  label="Heslo"
                  required
                  placeholder="6 a více znaků"
                  :rules="passwordRules"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                  @keypress.enter="registerWithEmailPassword"
                >
                </v-text-field>
                <v-btn
                  color="primary"
                  @click="registerWithEmailPassword"
                  class="mt-5"
                  >Vytvořit účet</v-btn
                >
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <v-alert
        icon="mdi-information-outline"
        border="left"
        text
        color="info"
        class="register__alert mx-auto animate__animated animate__fadeInUp animate__faster"
      >
        <div class="mb-2">Už máš účet? Přihlaš se!</div>
        <v-row no-gutters>
          <v-spacer></v-spacer>
          <v-col class="shrink">
            <v-btn :to="{ name: 'LoginUser' }" small>Přihlásit se</v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-container>
  </div>
</template>

<script>
import Header from "@/components/Header";
import GoogleLogin from "@/components/GoogleLogin";

export default {
  components: { "psc-header": Header, "psc-google-login": GoogleLogin },
  data() {
    return {
      formValid: false,
      firstName: "",
      lastName: "",
      email: "",
      emailRules: [
        (v) => !!v || "Email je povinný",
        (v) => /.+@.+\..+/.test(v) || "Email je neplatný",
      ],
      password: "",
      passwordRules: [
        (v) => !!v || "Heslo je povinné",
        (v) => v.length >= 6 || "Heslo musí mít 6 a více znaků",
      ],
      showPassword: false,
      panel: undefined,
    };
  },
  computed: {
    formWidth: function () {
      return this.panel == undefined ? "200px" : "100%";
    },
  },
  mounted() {
    this.$refs.register.addEventListener("animationend", () => {
      this.$vuetify.goTo(0);
    });
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    registerWithEmailPassword() {
      this.validate();
      if (this.formValid) {
        if (!this.firstName) this.firstName = "Jméno";
        if (!this.lastName) this.lastName = "Příjmení";
        let vm = this; // viewmodel
        this.$auth
          .createUserWithEmailAndPassword(this.email, this.password)
          .then((userCredentials) => {
            if (userCredentials.user) {
              userCredentials.user
                .updateProfile({
                  displayName: this.firstName + " " + this.lastName,
                })
                .then(function () {
                  vm.$alerts.success("Účet byl úspěšně vytvořen.");
                  vm.$router.replace({ name: "Home" });
                })
                .catch(function (error) {
                  console.error(error.code, error.message);
                  vm.$alerts.error(error.message);
                });
            }
          })
          .catch((error) => {
            console.error(error.code, error.message);
            vm.$alerts.error(error.message);
          });
      }
    },
  },
};
</script>

<style scoped>
.background {
  /* brown lighten-5 */
  /* brown lighten-4 */
  background: rgb(239, 235, 233);
  background: radial-gradient(
    circle,
    rgb(255, 255, 255) 0%,
    rgba(239, 235, 233, 1) 50%,
    rgba(215, 204, 200, 1) 100%
  );
}
.register {
  max-width: 600px;
}
.register__form {
  max-width: 300px;
}
.register__alert {
  max-width: 450px;
}
</style>
