<template>
  <div class="fill-height background-1">
    <div
      class="fill-height d-flex align-center"
      :class="{ 'background-2': isActive }"
    >
      <v-container>
        <div ref="logo" @click="animate" class="logo">
          <span
            class="blue--text text--darken-2 animate__animated animate__fadeInLeft"
            >P</span
          ><span
            class="red--text text--darken-1 animate__animated animate__fadeInDown"
            >u</span
          ><span
            class="green--text text--darken-1 animate__animated animate__flipInY"
            >s</span
          ><span
            class="blue--text text--darken-2 animate__animated animate__zoomIn"
            >í</span
          ><span
            class="yellow--text text--darken-1 animate__animated animate__fadeInUp"
            >c</span
          ><span
            class="red--text text--darken-1 animate__animated animate__fadeInRight"
            >i</span
          ><span
            class="animate__animated animate__bounceIn animate__delay-1s logo__small-font"
            >.cz</span
          >
        </div>

        <div v-if="user">
          <div v-if="webAccess" class="text-center">
            <v-btn
              x-large
              :to="{ name: 'PhotoBook' }"
              class="ma-5 primary animate__animated animate__bounceIn animate__delay-2s"
              >Vstoupit</v-btn
            >
          </div>
          <v-alert
            v-else
            icon="mdi-information-outline"
            border="left"
            text
            color="info"
            class="web-access-alert mx-auto"
          >
            <div class="mb-2">
              Téměř hotovo! Pro vstup na naše stránky je nezbytné ještě získat
              schválení od Administrátora webu. Už na tom pracujeme. Prosíme o
              strpení.
            </div>
          </v-alert>
        </div>
        <div v-else class="text-center">
          <v-btn
            x-large
            :to="{ name: 'LoginUser' }"
            class="ma-5 primary animate__animated animate__bounceIn animate__delay-2s"
            >Přihlásit se</v-btn
          >
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.getters.user ? true : false;
    },
    webAccess() {
      return this.$store.getters.claims.webAccess;
    },
    isActive() {
      // https://vuetifyjs.com/en/features/breakpoints/
      return !["xs", "sm", "md"].includes(this.$vuetify.breakpoint.name);
    },
  },
  methods: {
    animate() {
      let animations = [
        "animate__bounce",
        "animate__rubberBand",
        "animate__shakeX",
        "animate__swing",
        "animate__wobble",
        "animate__jello",
        "animate__flip",
        "animate__hinge",
      ];
      let animation = animations[Math.floor(Math.random() * animations.length)];
      let element = this.$refs.logo;
      element.classList.add("animate__animated", animation);
      element.addEventListener("animationend", () => {
        element.classList.remove(animation);
      });
    },
  },
};
</script>

<style scoped>
.logo {
  font-family: Kaileen;
  font-size: 300px;
  text-align: center;
  margin: 0 auto;
  cursor: pointer;
  user-select: none;
}
@media only screen and (max-width: 700px) {
  .logo {
    font-size: 150px;
  }
}
.logo__small-font {
  font-size: 50%;
}
span {
  display: inline-block;
}
.background-1 {
  /* green lighten-5 */
  /* green lighten-4 */
  background: rgb(232, 245, 233);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(232, 245, 233, 1) 50%,
    rgba(200, 230, 201, 1) 100%
  );
}
.background-2 {
  background: url("~@/assets/backgrounds/xmas-nameplate-2020.png") left bottom
      no-repeat,
    url("~@/assets/backgrounds/xmas-hearts-2020.png") right top no-repeat;
}
.web-access-alert {
  width: 600px;
  max-width: 80%;
}
</style>
