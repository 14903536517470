<template>
  <div class="background fill-height">
    <v-container>
      <psc-header title="Přihlášení do Pusíků"></psc-header>
      <div
        ref="login"
        class="login mx-auto text-center my-15 pa-10 animate__animated animate__zoomIn animate__faster"
      >
        <psc-google-login></psc-google-login>
        <v-form ref="form" v-model="formValid" class="login__form mx-auto">
          <v-text-field
            v-model="email"
            label="E-mail"
            required
            :rules="emailRules"
          ></v-text-field>
          <v-text-field
            v-model="password"
            label="Heslo"
            required
            :rules="passwordRules"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            @keypress.enter="loginWithEmailPassword"
          ></v-text-field>
          <v-btn color="primary" @click="loginWithEmailPassword" class="mt-5"
            >Přihlásit se</v-btn
          >
        </v-form>
      </div>
      <v-alert
        icon="mdi-information-outline"
        border="left"
        text
        color="info"
        class="login__alert mx-auto animate__animated animate__fadeInUp animate__faster"
      >
        <div class="mb-2">
          Chceš se stát členem rodiny Pusíků? Vytvoř si účet!
        </div>
        <v-row no-gutters>
          <v-spacer></v-spacer>
          <v-col class="shrink">
            <v-btn @click="goToRegisterUser" small>Vytvořit účet</v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-container>
  </div>
</template>

<script>
import Header from "@/components/Header";
import GoogleLogin from "@/components/GoogleLogin";

export default {
  components: { "psc-header": Header, "psc-google-login": GoogleLogin },
  data() {
    return {
      formValid: false,
      email: "",
      emailRules: [
        (v) => !!v || "Email je povinný",
        (v) => /.+@.+\..+/.test(v) || "Email je neplatný",
      ],
      password: "",
      passwordRules: [(v) => !!v || "Heslo je povinné"],
      showPassword: false,
    };
  },
  mounted() {
    this.$refs.login.addEventListener("animationend", () => {
      this.$vuetify.goTo(0);
    });
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    loginWithEmailPassword() {
      this.validate();
      if (this.formValid) {
        let vm = this;
        this.$auth
          .signInWithEmailAndPassword(this.email, this.password)
          .then((/*user*/) => {
            vm.$router.replace({ name: "Home" });
          })
          .catch((error) => {
            console.error(error.code, error.message);
            vm.$alerts.error(error.message);
          });
      }
    },
    goToRegisterUser() {
      this.$router.replace({ name: "RegisterUser" });
    },
  },
};
</script>

<style scoped>
.background {
  /* teal lighten-5 */
  /* teal lighten-4 */
  background: rgb(224, 242, 241);
  background: radial-gradient(
    circle,
    rgb(255, 255, 255) 0%,
    rgba(224, 242, 241, 1) 50%,
    rgba(178, 223, 219, 1) 100%
  );
}
.login {
  max-width: 550px;
}
.login__form {
  max-width: 300px;
}
.login__alert {
  max-width: 450px;
}
</style>
