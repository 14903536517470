import {
  auth,
  google,
  database,
  firestore,
  functions,
  storage,
} from "./firebase-config";

export default {
  install(Vue /*, options*/) {
    Vue.prototype.$auth = Vue.auth = auth;
    Vue.prototype.$google = Vue.google = google;
    Vue.prototype.$database = Vue.database = database;
    Vue.prototype.$firestore = Vue.firestore = firestore;
    Vue.prototype.$functions = Vue.functions = functions;
    Vue.prototype.$storage = Vue.storage = storage;
  },
};
