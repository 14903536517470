<template>
  <div class="d-flex align-center">
    <v-divider></v-divider>
    <span v-if="text" class="mx-3 no-select">{{ text }}</span>
    <v-divider v-if="text"></v-divider>
  </div>
</template>

<script>
export default {
  props: ["text"],
};
</script>
