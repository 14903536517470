import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    claims: {},
    navigation: [
      { name: "Home", title: "Domů", icon: "mdi-home-outline", active: true },
      {
        name: "LoginUser",
        title: "Přihlásit se",
        icon: "mdi-login-variant",
        active: false,
      },
      {
        name: "RegisterUser",
        title: "Vytvořit účet",
        icon: "mdi-account-plus-outline",
        active: false,
      },
      {
        name: "Administration",
        title: "Administrace",
        icon: "mdi-account-key",
        active: false,
      },
      {
        name: "PhotoBook",
        title: "Fotokniha",
        icon: "mdi-camera",
        active: false,
      },
    ],
  },
  mutations: {
    updateUser(state, user) {
      state.user = user;
    },
    updateClaims(state, claims) {
      state.claims = claims;
    },
    activateNavigationItem(state, name) {
      let index = getNavigationItemIndex(state, name);
      state.navigation[index].active = true;
    },
    deactivateNavigationItem(state, name) {
      let index = getNavigationItemIndex(state, name);
      state.navigation[index].active = false;
    },
  },
  actions: {
    updateNavigation(context) {
      if (context.state.user) {
        context.commit("deactivateNavigationItem", "LoginUser");
        context.commit("deactivateNavigationItem", "RegisterUser");
      } else {
        context.commit("activateNavigationItem", "LoginUser");
        context.commit("activateNavigationItem", "RegisterUser");
      }
      if (context.state.claims.admin) {
        context.commit("activateNavigationItem", "Administration");
      } else {
        context.commit("deactivateNavigationItem", "Administration");
      }
      if (context.state.claims.webAccess) {
        context.commit("activateNavigationItem", "PhotoBook");
      } else {
        context.commit("deactivateNavigationItem", "PhotoBook");
      }
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
    claims(state) {
      return state.claims;
    },
    navigation(state) {
      // returns only active items
      return state.navigation.filter((item) => {
        return item.active == true;
      });
    },
  },
  modules: {},
});

// private functions

function getNavigationItemIndex(state, name) {
  return state.navigation.findIndex((item) => item.name == name);
}
