<template>
  <div class="teal lighten-4 fill-height">
    <v-container>
      <psc-header title="Administration"></psc-header>
      <v-card class="table mx-auto">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="users"
          :search="search"
          :sort-by="['name']"
          :loading="loading"
        >
          <template v-slot:[`item.emailVerified`]="{ item }">
            <v-icon v-if="item.emailVerified" color="green">mdi-check</v-icon>
            <v-icon v-else color="red">mdi-close</v-icon>
          </template>
          <template v-slot:[`item.provider`]="{ item }">
            <img
              v-if="item.provider == 'google.com'"
              src="@/assets/icons/google-icon.svg"
              alt="GOOGLE"
            />
            <v-icon v-if="item.provider == 'password'"
              >mdi-email-outline</v-icon
            >
          </template>
          <template v-slot:[`item.access`]="{ item }">
            <v-simple-checkbox
              v-model="item.access"
              :ripple="false"
              @click="webAccess(item)"
            ></v-simple-checkbox>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Header from "@/components/Header";
export default {
  components: { "psc-header": Header },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Display Name",
          align: "start",
          value: "name",
        },
        { text: "Email", value: "email" },
        {
          text: "Email Verified",
          align: "center",
          sortable: false,
          value: "emailVerified",
        },
        {
          text: "Provider",
          align: "center",
          sortable: false,
          value: "provider",
        },
        {
          text: "Web Access",
          align: "center",
          sortable: false,
          value: "access",
        },
      ],
      users: [],
      loading: true,
    };
  },
  created() {
    let vm = this;
    let listUsers = this.$functions.httpsCallable("listUsers");
    listUsers()
      .then((result) => {
        console.log("USERS:", result.data.users);
        result.data.users.forEach((user) => {
          let provider = user.providerData
            ? user.providerData[0].providerId
            : null;
          let newUser = {
            name: user.displayName,
            email: user.email,
            emailVerified: user.emailVerified,
            provider: provider,
            access: user.customClaims && user.customClaims.webAccess,
          };
          vm.users.push(newUser);
        });
        vm.loading = false;
      })
      .catch((error) => {
        vm.$alerts.error(error.code + ": " + error.message);
      });
  },
  methods: {
    webAccess(item) {
      let vm = this;
      let userWebAccess = item.access
        ? this.$functions.httpsCallable("grantUserWebAccess")
        : this.$functions.httpsCallable("denyUserWebAccess");

      userWebAccess({ email: item.email })
        .then((result) => {
          if (result.data.code == "OK") vm.$alerts.success(result.data.message);
          else vm.$alerts.error(result.data.message);
        })
        .catch((error) => {
          vm.$alerts.error(error.details + ": " + error.message);
        });
    },
  },
};
</script>

<style scoped>
.table {
  width: 800px;
  max-width: 100%;
}
</style>
