<template>
  <v-fab-transition>
    <v-btn
      v-show="showButton"
      @click="toTop"
      v-scroll="onScroll"
      fixed
      fab
      dark
      large
      bottom
      right
      color="blue-grey"
      ><v-icon>mdi-arrow-up</v-icon>
    </v-btn>
  </v-fab-transition>
</template>

<script>
export default {
  data() {
    return {
      showButton: false,
    };
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.showButton = top > 100;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>

<style scoped></style>
