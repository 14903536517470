<template>
  <v-app>
    <!-- see https://vuetifyjs.com/en/components/application/ -->
    <psc-alerts></psc-alerts>
    <psc-navigation-menu></psc-navigation-menu>
    <v-main>
      <router-view></router-view>
    </v-main>
    <!-- <v-footer app></v-footer> -->
    <psc-scroll-top-button></psc-scroll-top-button>
    <!-- <psc-cookies-warning></psc-cookies-warning> -->
  </v-app>
  <!-- In order for your application to work properly, you must wrap it in a v-app component. -->
  <!-- see https://vuetifyjs.com/en/components/application/ -->
</template>

<script>
import Alerts from "@/components/singletons/Alerts";
import NavigationMenu from "@/components/singletons/NavigationMenu";
import ScrollTopButton from "@/components/singletons/ScrollTopButton";
// import CookiesWarning from "@/components/singletons/CookiesWarning";

export default {
  name: "App",
  components: {
    "psc-alerts": Alerts,
    "psc-navigation-menu": NavigationMenu,
    "psc-scroll-top-button": ScrollTopButton,
    // "psc-cookies-warning": CookiesWarning,
  },
};
</script>
