import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import Home from "@/views/Home";
import LoginUser from "@/views/LoginUser";
import RegisterUser from "@/views/RegisterUser";
import About from "@/views/About";
import PhotoBook from "@/views/PhotoBook";
import Administration from "@/views/Administration";
// import SoundsHome from "@/views/applications/sounds/SoundsHome";
import NotFound from "@/views/NotFound";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "LoginUser",
    component: LoginUser,
  },
  {
    path: "/register",
    name: "RegisterUser",
    component: RegisterUser,
  },
  {
    path: "/about",
    name: "About",
    meta: { auth: true },
    component: About,
  },
  {
    path: "/photo-book",
    name: "PhotoBook",
    meta: { auth: true, claim: "webAccess" },
    component: PhotoBook,
  },
  {
    path: "/administration",
    name: "Administration",
    meta: { auth: true, claim: "admin" },
    component: Administration,
  },
  // {
  //   path: "/applications/sounds",
  //   name: "Sounds",
  //   component: SoundsHome,
  // },
  {
    path: "/:notFound(.*)",
    name: "NotFound",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// guards
router.beforeEach((to, from, next) => {
  let user = store.getters.user;
  let claims = store.getters.claims;

  if (to.meta.auth) {
    // expected authentication
    if (user) {
      // authenticated
      if (to.meta.claim) {
        // expected authorization
        if (claims[to.meta.claim]) {
          // authorized
          console.log("ROUTER:", {
            from: from.name,
            to: to.name,
            message: "Authorized",
            claim: to.meta.claim,
          });
          next();
        } else {
          // UNAUTHORIZED
          console.log("ROUTER:", {
            from: from.name,
            to: to.name,
            message: "UNAUTHORIZED",
          });
          next({ name: "Home" });
        }
      } else {
        // anyone authenticated can access
        console.log("ROUTER:", {
          from: from.name,
          to: to.name,
          message: "Authenticated",
        });
        next();
      }
    } else {
      // NOT AUTHENTICATED
      console.log("ROUTER:", {
        from: from.name,
        to: to.name,
        message: "NOT AUTHENTICATED",
      });
      next({ name: "LoginUser" });
    }
  } else {
    // anyone can access
    console.log("ROUTER:", { from: from.name, to: to.name, message: "OK" });
    next();
  }
});

export default router;
