<template>
  <div class="fill-height d-flex align-center">
    <div class="text-center mx-auto">
      <h1
        class="display-4"
        style="margin-bottom: -10%; position: relative; z-index: 5"
      >
        404
      </h1>
      <img src="@/assets/404-caveman.gif" alt="" width="100%" />
      <div style="margin-top: -10%; position: relative; z-index: 5">
        <h2>Vypadá to, že jsi zabloudil</h2>
        <p>Stránka, kterou hledáš, není k dispozici.</p>
        <v-btn @click="goHome" class="primary">Zpět na domovskou stránku</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goHome() {
      this.$router.push({ name: "Home" });
    },
  },
};
</script>

<style scoped></style>
