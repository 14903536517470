<template>
  <div class="alerts__box mt-4">
    <v-alert
      v-for="alert in alerts"
      :key="alert.key"
      v-model="alert.show"
      :type="alert.type"
      transition="slide-y-transition"
      dismissible
      @input="dismiss(alert)"
    >
      {{ alert.message }}
    </v-alert>
  </div>
</template>

<script>
export default {
  computed: {
    alerts() {
      return this.$alerts.$data.alerts;
    },
  },
  methods: {
    dismiss(alert) {
      this.$alerts.remove(alert);
    },
  },
};
</script>

<style scoped>
.alerts__box {
  position: fixed;
  width: 80%;
  max-width: 400px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 5;
}
</style>
